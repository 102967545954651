import { Fragment } from 'react';
import { Link, useParams, LinkProps, generatePath, useRouteMatch } from 'react-router-dom';
import { Agent, Policy } from './types';

function NiceFormatted({ keyValue, value }: { keyValue: string, value: string }) {
    return (
        <div className="nice-formatted-kv"><label htmlFor={keyValue}>{keyValue.replace(/([A-Z])/g, ' $1')}{': '}</label><span>{value}</span></div>
    )
}

function DisablableLink({ disabled, ...linkProps }: { disabled: boolean } & LinkProps) {
    return disabled ? <span>{linkProps.children}</span> : <Link {...linkProps}>{linkProps.children}</Link>
}


function CustomerPage({ data }: { data: Agent }) {
    const params = useParams<{ page?: string, customerId: string }>();
    const { customerId } = params
    const match = useRouteMatch()
    const page = parseInt(params.page || "1")
    const pageSize = 5;

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Premium',
            dataIndex: 'premium',
            key: 'premium'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Effective Date',
            dataIndex: 'effectiveDate',
            key: 'effectiveDate',
        },
        {
            title: 'Termination Date',
            dataIndex: 'terminationDate',
            key: 'terminationDate'
        }
    ]
    const pageData = data.customer.policies.slice((page - 1) * pageSize, page * pageSize)
    return (
        <div className="container">
            <div className="card agency-details details-card">
                <div className="card-body">
                    <h4 className="card-title">Agent Details</h4>
                    <NiceFormatted keyValue="name" value={data.name} />
                    <NiceFormatted keyValue="producerCode" value={data.producerCode} />
                    <NiceFormatted keyValue="agencyName" value={data.agency.name} />
                    <NiceFormatted keyValue="agencyCode" value={data.agency.agencyCode} />
                </div>
            </div>
            <div className="card customer-details details-card">
                <div className="card-body">
                    <h4 className="card-title">Customer Details</h4>
                    <div><label htmlFor='name'>Name:</label><span>{data.customer.name}</span></div>
                    <div><label>Id:</label><span>{customerId}</span></div>
                    <div style={{ display: 'none' }}><label>SSN:</label><span>{data.customer.ssn}</span></div>
                    <label>Email: </label>{data.customer.email}
                    <div>Address: {data.customer.address}</div>
                </div>
            </div>
            <div className="card policy-details">
                <h4>Policies</h4>
                <table className="table">
                    <thead>
                        <tr>
                            {columns.map(col => <th key={col.key}>{col.title}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {pageData.map((row, i) => <Fragment key={row.id}>
                            <tr data-bs-toggle="collapse" data-bs-target={`#trcoll${i}`} aria-controls={`trcoll${i}`} className="policy-info-row">
                                {columns.map(col => {
                                    const val = row[(col.dataIndex as keyof Policy)];
                                    return (<td key={col.key}>{val instanceof Date ? val.toLocaleDateString() : val}</td>)
                                })}
                            </tr>
                            {/* Yes, this makes this tightly coupled to the data, can fix later if we expand the project */}
                            <tr id={`trcoll${i}`} className="collapse">
                                <td colSpan={columns.length} className="details-row">
                                    <div>
                                        <h5>Details</h5>
                                        Last Payment Date: {row.lastPaymentDate.toLocaleDateString()} <br />
                                        Commission Rate: {row.commissionRate}% <br />
                                        Number of Insureds: {row.numberOfInsureds}
                                    </div>
                                </td>
                            </tr>
                        </Fragment>)}
                    </tbody>
                    <tfoot><tr><td colSpan={columns.length}>
                        <DisablableLink disabled={page <= 1} to={generatePath(match.path, { ...params, page: page - 1 })}>{'< Prev'}</DisablableLink>
                        <DisablableLink disabled={page >= data.customer.policies.length / pageSize} to={generatePath(match.path, { ...params, page: page + 1 })}>{'Next >'}</DisablableLink>
                    </td></tr></tfoot>
                </table>
            </div>
        </div >
    )
}

export default CustomerPage