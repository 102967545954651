import React from 'react';
import './App.css';
import faker from 'faker'
import { BrowserRouter as Router, Switch, Route, Redirect, Link } from 'react-router-dom';
import CustomerPage from './CustomerPage';
import { Agent, Customer, Policy } from './types';
import bootstrap from 'bootstrap'
import CustomerPageList from './CustomerPageList';


function sample<T>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)]
}

function generatePolicy(accountIds: string[]): Policy {
  const policyTypes = ['homeowners', 'renters', 'auto', 'umbrella', 'cyber']
  const policyStatuses = ['endorsement_pending', 'claim_pending', 'claim_rejected', 'active', 'pending_cancelation']
  return {
    id: faker.internet.password(10, false, /[a-z0-9]/),
    policyType: sample(policyTypes),
    premium: faker.commerce.price(0, 10000),
    effectiveDate: faker.date.recent(),
    terminationDate: faker.date.future(),
    accountId: sample(accountIds),
    lastPaymentDate: faker.date.recent(),
    commissionRate: Math.round(Math.random() * 20),
    numberOfInsureds: Math.floor(Math.random() * 5) + 1,
    status: sample(policyStatuses)
  }
}

function generateCustomer(): Customer {
  const accountIds = Array(3).fill(null).map(() => faker.internet.password(10, false, /[a-z0-9]/))
  return {
    name: faker.name.findName(),
    address: faker.address.streetAddress(),
    email: faker.internet.email(),
    ssn: Math.floor(Math.random() * 1000000000).toString(),
    dateOfBirth: faker.date.between(new Date('1/1/1952'), new Date('1/1/2000')),
    profession: faker.name.jobTitle(),
    creditScore: faker.datatype.number(400) + 450,
    policies: Array(11).fill(null).map(() => generatePolicy(accountIds))
  }
}

function generateAgent(customer?: Customer): Agent {
  return {
    name: faker.name.findName(),
    producerCode: faker.internet.password(8, false, /[A-Z0-9]/),
    customer: generateCustomer(),
    agency: {
      name: faker.company.companyName(),
      agencyCode: faker.internet.password(8, false, /[A-Z0-9]/),
    }
  }
}

const agent1: Agent = {
  "name": "Darin Haag",
  "producerCode": "VUZ4OBWU",
  "customer": {
    "name": "Sherri Ritchie",
    "address": "61734 Edgardo Brooks",
    "email": "Gayle63@yahoo.com",
    "ssn": "346304890",
    "dateOfBirth": new Date("1985-03-08T05:26:48.713Z"),
    "profession": "Principal Assurance Supervisor",
    "creditScore": 454,
    "policies": [
      {
        "id": "tzmgis4dk3",
        "policyType": "renters",
        "premium": "5757.00",
        "effectiveDate": new Date("2021-09-10T11:27:36.605Z"),
        "terminationDate": new Date("2022-01-18T08:07:35.354Z"),
        "accountId": "shvakblpvv",
        "lastPaymentDate": new Date("2021-09-10T06:33:08.917Z"),
        "commissionRate": 18,
        "numberOfInsureds": 2,
        "status": "pending_cancelation"
      },
      {
        "id": "j310ut4g14",
        "policyType": "cyber",
        "premium": "9887.00",
        "effectiveDate": new Date("2021-09-10T16:58:03.742Z"),
        "terminationDate": new Date("2022-04-27T21:21:01.300Z"),
        "accountId": "f9vr8dcccx",
        "lastPaymentDate": new Date("2021-09-10T09:30:28.039Z"),
        "commissionRate": 1,
        "numberOfInsureds": 5,
        "status": "endorsement_pending"
      },
      {
        "id": "s0243utqc9",
        "policyType": "cyber",
        "premium": "5339.00",
        "effectiveDate": new Date("2021-09-10T03:30:21.226Z"),
        "terminationDate": new Date("2022-07-07T12:11:10.442Z"),
        "accountId": "shvakblpvv",
        "lastPaymentDate": new Date("2021-09-10T04:12:06.480Z"),
        "commissionRate": 3,
        "numberOfInsureds": 4,
        "status": "endorsement_pending"
      },
      {
        "id": "fanbk4an5k",
        "policyType": "homeowners",
        "premium": "7457.00",
        "effectiveDate": new Date("2021-09-09T19:20:32.517Z"),
        "terminationDate": new Date("2021-11-20T02:44:26.151Z"),
        "accountId": "shvakblpvv",
        "lastPaymentDate": new Date("2021-09-10T11:37:59.080Z"),
        "commissionRate": 13,
        "numberOfInsureds": 1,
        "status": "active"
      },
      {
        "id": "6vzdbmyptv",
        "policyType": "umbrella",
        "premium": "295.00",
        "effectiveDate": new Date("2021-09-09T23:19:45.785Z"),
        "terminationDate": new Date("2022-03-09T11:03:14.816Z"),
        "accountId": "f9vr8dcccx",
        "lastPaymentDate": new Date("2021-09-10T01:02:26.006Z"),
        "commissionRate": 15,
        "numberOfInsureds": 2,
        "status": "endorsement_pending"
      },
      {
        "id": "yg1rv0s70s",
        "policyType": "renters",
        "premium": "1572.00",
        "effectiveDate": new Date("2021-09-09T22:36:03.446Z"),
        "terminationDate": new Date("2021-12-10T15:38:24.859Z"),
        "accountId": "shvakblpvv",
        "lastPaymentDate": new Date("2021-09-10T14:01:36.310Z"),
        "commissionRate": 8,
        "numberOfInsureds": 5,
        "status": "endorsement_pending"
      },
      {
        "id": "9vkwjijmvt",
        "policyType": "cyber",
        "premium": "3969.00",
        "effectiveDate": new Date("2021-09-10T14:07:25.335Z"),
        "terminationDate": new Date("2022-03-08T06:07:32.249Z"),
        "accountId": "f9vr8dcccx",
        "lastPaymentDate": new Date("2021-09-10T02:51:20.090Z"),
        "commissionRate": 10,
        "numberOfInsureds": 3,
        "status": "endorsement_pending"
      },
      {
        "id": "wpk6r2j1yk",
        "policyType": "auto",
        "premium": "6002.00",
        "effectiveDate": new Date("2021-09-10T14:13:42.357Z"),
        "terminationDate": new Date("2022-06-18T05:23:04.943Z"),
        "accountId": "shvakblpvv",
        "lastPaymentDate": new Date("2021-09-09T20:42:19.278Z"),
        "commissionRate": 14,
        "numberOfInsureds": 1,
        "status": "pending_cancelation"
      },
      {
        "id": "rzk89nxr50",
        "policyType": "renters",
        "premium": "463.00",
        "effectiveDate": new Date("2021-09-10T12:00:44.908Z"),
        "terminationDate": new Date("2021-12-22T05:53:52.422Z"),
        "accountId": "shvakblpvv",
        "lastPaymentDate": new Date("2021-09-10T15:20:31.422Z"),
        "commissionRate": 18,
        "numberOfInsureds": 2,
        "status": "endorsement_pending"
      },
      {
        "id": "h2mf6vi2q9",
        "policyType": "auto",
        "premium": "2244.00",
        "effectiveDate": new Date("2021-09-10T10:19:13.353Z"),
        "terminationDate": new Date("2022-01-24T15:37:21.803Z"),
        "accountId": "u0l9ja12kz",
        "lastPaymentDate": new Date("2021-09-10T08:08:57.368Z"),
        "commissionRate": 3,
        "numberOfInsureds": 2,
        "status": "claim_pending"
      },
      {
        "id": "iqgho6bp5s",
        "policyType": "umbrella",
        "premium": "2386.00",
        "effectiveDate": new Date("2021-09-10T07:31:38.045Z"),
        "terminationDate": new Date("2022-01-28T13:12:11.653Z"),
        "accountId": "shvakblpvv",
        "lastPaymentDate": new Date("2021-09-10T14:34:36.816Z"),
        "commissionRate": 15,
        "numberOfInsureds": 5,
        "status": "active"
      }
    ]
  },
  "agency": {
    "name": "Beier, Quitzon and Kirlin",
    "agencyCode": "H62E9ALA"
  }
}
const agent2: Agent = {
  "name": "Abel Konopelski",
  "producerCode": "IMELPT0V",
  "customer": {
    "name": "Teresa Goodwin V",
    "address": "8280 McCullough Lake",
    "email": "Tabitha_Barton27@hotmail.com",
    "ssn": "801642917",
    "dateOfBirth": new Date("1983-12-25T07:49:57.801Z"),
    "profession": "Human Optimization Liaison",
    "creditScore": 476,
    "policies": [
      {
        "id": "pbvzh6046i",
        "policyType": "homeowners",
        "premium": "450.00",
        "effectiveDate": new Date("2021-09-09T21:06:10.274Z"),
        "terminationDate": new Date("2021-10-25T20:13:03.348Z"),
        "accountId": "ojntw7tion",
        "lastPaymentDate": new Date("2021-09-10T14:37:46.465Z"),
        "commissionRate": 2,
        "numberOfInsureds": 2,
        "status": "claim_rejected"
      },
      {
        "id": "xt9d2t2cpy",
        "policyType": "umbrella",
        "premium": "9027.00",
        "effectiveDate": new Date("2021-09-09T23:09:14.648Z"),
        "terminationDate": new Date("2022-06-07T11:30:04.573Z"),
        "accountId": "ojntw7tion",
        "lastPaymentDate": new Date("2021-09-10T00:54:35.723Z"),
        "commissionRate": 0,
        "numberOfInsureds": 3,
        "status": "active"
      },
      {
        "id": "f4p0f5wfqy",
        "policyType": "cyber",
        "premium": "9287.00",
        "effectiveDate": new Date("2021-09-10T12:33:55.813Z"),
        "terminationDate": new Date("2022-05-30T02:02:04.849Z"),
        "accountId": "ojntw7tion",
        "lastPaymentDate": new Date("2021-09-10T04:57:18.696Z"),
        "commissionRate": 1,
        "numberOfInsureds": 1,
        "status": "pending_cancelation"
      },
      {
        "id": "8z09phyy10",
        "policyType": "cyber",
        "premium": "6502.00",
        "effectiveDate": new Date("2021-09-10T12:54:37.181Z"),
        "terminationDate": new Date("2022-04-08T05:53:24.797Z"),
        "accountId": "vp87vmmyc3",
        "lastPaymentDate": new Date("2021-09-10T00:06:37.160Z"),
        "commissionRate": 0,
        "numberOfInsureds": 4,
        "status": "endorsement_pending"
      },
      {
        "id": "vbngv4xubx",
        "policyType": "renters",
        "premium": "5010.00",
        "effectiveDate": new Date("2021-09-09T21:43:05.615Z"),
        "terminationDate": new Date("2022-01-22T15:55:23.238Z"),
        "accountId": "ojntw7tion",
        "lastPaymentDate": new Date("2021-09-10T15:06:44.004Z"),
        "commissionRate": 9,
        "numberOfInsureds": 4,
        "status": "pending_cancelation"
      },
      {
        "id": "ml7u10et8w",
        "policyType": "cyber",
        "premium": "3336.00",
        "effectiveDate": new Date("2021-09-10T01:28:10.635Z"),
        "terminationDate": new Date("2022-01-09T17:22:42.819Z"),
        "accountId": "vp87vmmyc3",
        "lastPaymentDate": new Date("2021-09-10T07:44:09.299Z"),
        "commissionRate": 1,
        "numberOfInsureds": 3,
        "status": "active"
      },
      {
        "id": "w5310n0ad9",
        "policyType": "cyber",
        "premium": "8674.00",
        "effectiveDate": new Date("2021-09-10T11:02:09.038Z"),
        "terminationDate": new Date("2022-08-22T15:32:32.665Z"),
        "accountId": "vp87vmmyc3",
        "lastPaymentDate": new Date("2021-09-10T15:55:08.613Z"),
        "commissionRate": 10,
        "numberOfInsureds": 2,
        "status": "endorsement_pending"
      },
      {
        "id": "4byhletk18",
        "policyType": "renters",
        "premium": "3451.00",
        "effectiveDate": new Date("2021-09-10T04:32:24.538Z"),
        "terminationDate": new Date("2022-05-17T10:27:59.260Z"),
        "accountId": "ojntw7tion",
        "lastPaymentDate": new Date("2021-09-10T15:38:00.520Z"),
        "commissionRate": 16,
        "numberOfInsureds": 1,
        "status": "claim_rejected"
      },
      {
        "id": "bt0bnkyj0u",
        "policyType": "homeowners",
        "premium": "8022.00",
        "effectiveDate": new Date("2021-09-09T18:44:23.326Z"),
        "terminationDate": new Date("2022-07-27T06:05:18.848Z"),
        "accountId": "z0itea4hld",
        "lastPaymentDate": new Date("2021-09-10T07:28:09.837Z"),
        "commissionRate": 12,
        "numberOfInsureds": 2,
        "status": "endorsement_pending"
      },
      {
        "id": "s41eb75w2q",
        "policyType": "renters",
        "premium": "6466.00",
        "effectiveDate": new Date("2021-09-10T03:35:25.579Z"),
        "terminationDate": new Date("2021-12-05T23:53:11.926Z"),
        "accountId": "ojntw7tion",
        "lastPaymentDate": new Date("2021-09-10T04:22:08.300Z"),
        "commissionRate": 10,
        "numberOfInsureds": 2,
        "status": "pending_cancelation"
      },
      {
        "id": "q1s6b7eq5w",
        "policyType": "renters",
        "premium": "5130.00",
        "effectiveDate": new Date("2021-09-10T01:04:05.386Z"),
        "terminationDate": new Date("2021-10-04T11:08:49.024Z"),
        "accountId": "ojntw7tion",
        "lastPaymentDate": new Date("2021-09-09T22:16:47.969Z"),
        "commissionRate": 2,
        "numberOfInsureds": 3,
        "status": "endorsement_pending"
      }
    ]
  },
  "agency": {
    "name": "Kemmer - Berge",
    "agencyCode": "BQUI1ONR"
  }
}

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/placeholder_carrier">
            <nav className="navbar navbar-light bg-light">
              <div className="container-fluid">
                <a className="navbar-brand" href="#">Placeholder Insurance</a>
              </div>
            </nav>
            <Switch>
              <Route path="/placeholder_carrier/:customerId/policies/:page">
                <CustomerPage data={agent1} />
              </Route>
              <Route path="/placeholder_carrier/f02dkl4e" exact>
                <Redirect to="/placeholder_carrier/f02dkl4e/policies/1" />
              </Route>
            </Switch>
          </Route>
          <Route path="/mock_indemnity/:customerId">
            <nav className="navbar navbar-light bg-light">
              <div className="container-fluid">
                <a className="navbar-brand" href="#">Mock Indemnity</a>
              </div>
            </nav>
            <CustomerPageList agent={agent2} />
          </Route>
          <Route path="/" exact>
            <h2>Sample carrier pages</h2>
            <ul style={{ listStyle: 'none' }}>
              <li>
                <Link to="/placeholder_carrier/f02dkl4e">Placeholder Carrier</Link>
              </li>
              <li>
                <Link to="/mock_indemnity/a0dfjw9a">Mock Indemnity</Link>
              </li>
            </ul>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
