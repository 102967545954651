import { useParams } from "react-router";
import { Agent } from "./types";

function StructuredData({ keyName, value }: { keyName: string, value: string }) {
    return (<>
        <label htmlFor={keyName}>{keyName.replace(/([A-Z])/g, ' $1')}:</label><span className={`${keyName} value-holder`}>{value}</span>
    </>)
}


function DefinitionData({ keyName, value }: { keyName: string, value: string }) {
    return (<>
        <dt className={`definition-term ${keyName}-label`}>{keyName.replace(/([A-Z])/g, ' $1')}:</dt><dd data-value-for={keyName} className={`value-${keyName} value-holder`}>{value}</dd>
    </>)
}

function CustomerPageList({ agent }: { agent: Agent }) {
    const { customer } = agent;
    const { customerId } = useParams<{ customerId: string }>()
    return (
        <div>
            <div className="container"><div className="row">
                <div className="col"><div className="card agent-detail">
                    <div className="card-body">
                        <h4 className="card-title">Agent</h4>
                        <dl>
                            <DefinitionData keyName="name" value={agent.name} />
                            <DefinitionData keyName="producerCode" value={agent.producerCode} />
                            <DefinitionData keyName="agencyName" value={agent.agency.name} />
                            <DefinitionData keyName="agencyCode" value={agent.agency.agencyCode} />
                        </dl>
                    </div>
                </div></div>
                <div className="col"><div className="card customer-detail">
                    <div className="card-body">
                        <h4 className="card-title">Customer</h4>
                        <dl>
                            <DefinitionData keyName="name" value={customer.name} />
                            <DefinitionData keyName="id" value={customerId} />
                            <DefinitionData keyName="email" value={customer.email} />
                            <DefinitionData keyName="address" value={customer.address} />
                        </dl>
                    </div>
                </div></div>
            </div></div>
            <h4>Policies</h4>
            <ul className="list-group policy-ul generic-list" id="policy-list">
                {agent.customer.policies.map(policy => (
                    <li key={policy.id} className="list-group-item">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <StructuredData keyName="id" value={policy.id} />
                                </div>
                                <div className="col">
                                    <StructuredData keyName="premium" value={policy.premium} />
                                </div>
                                <div className="col">
                                    <StructuredData keyName="status" value={policy.status} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <StructuredData keyName="effectiveDate" value={policy.effectiveDate.toLocaleDateString()} />
                                </div>
                                <div className="col">
                                    <StructuredData keyName="terminationDate" value={policy.terminationDate.toLocaleDateString()} />
                                </div>
                                <div className="col">
                                    <StructuredData keyName="lastPaymentDate" value={policy.lastPaymentDate.toLocaleDateString()} />
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul></div>
    )
}

export default CustomerPageList